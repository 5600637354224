import React from "react";
import { useState, useEffect } from "react";
import { BarChart, Bar, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const EventData = () => {
    const [lastEvent, setLastEvent] = useState({});
    const [semiLastEvent, setSemiLastEvent] = useState({});

    const [consumptionsData, setConsumptionsData] = useState([]);
    const [entrancesData, setEntrancesData] = useState([]);

    useEffect(() => {
        fetch("https://backend.clubpass.it/organizer/stats/latest/10", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
            if (response.status === 200) {
                return response.json();
            }
        })
            .then((data) => {
                console.log(data.stats);
                // format the data to be used by the chart
                let formattedData = [];
                //sort the data by date
                data.stats.sort((a, b) => {
                    return new Date(a.eventStart) - new Date(b.eventStart);
                });
                data.stats.forEach((stat) => {
                    formattedData.push({
                        // format the date to be displayed in the chart
                        name: new Date(stat.eventStart).toLocaleDateString("it-IT", { day: "numeric", month: "short" }),
                        consumazioni: stat.consumptions,
                        ingressi: stat.entrances
                    })
                });
                setConsumptionsData(formattedData);
                setLastEvent(data.stats[data.stats.length - 1]);
                setSemiLastEvent(data.stats[data.stats.length - 2]);
            })
            .catch((error) => {
                console.log(error);
            });

        fetch("https://backend.clubpass.it/organizer/stats/now", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
            if (response.status === 200) {
                return response.json();
            }
        })
            .then((data) => {
                console.log(data);
            })
            .catch((error) => {
                console.log(error);
            }
            );

            getEventData(lastEvent);
    }, []);


    const getEventData = (event) => {
        fetch("https://backend.clubpass.it/organizer/stats/event/sampled/" + 2, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
            if (response.status === 200) {
                return response.json();
            }
        }
        )
            .then((data) => {
                console.log(data.data);
                let formattedData = [];
                data.data.forEach((stat) => {
                    formattedData.push({
                        // format the date to be displayed in the chart with only hours and no da
                        name: new Date(stat.rounded_time).toLocaleDateString("it-IT", { hour: "numeric", minute: "numeric" }).split(" ")[1],
                        consumazioni: stat.consumptions,
                        ingressi: stat.entrances,
                        uscite: stat.exits
                    })
                });
                // Sort the data by date
                formattedData.sort((a, b) => {
                    return new Date(a.name) - new Date(b.name);
                });
                // Find people currently inside the venue
                let peopleInside = 0;
                let currentSales = 0;
                formattedData.forEach((stat) => {
                    peopleInside += stat.ingressi - stat.uscite;
                    stat.Persone = peopleInside;
                    currentSales += stat.consumazioni;
                    stat.Vendite = currentSales;
                });
                setEntrancesData(formattedData);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", alignContent: "flex-end", alignItems: "flex-start" }}>
            <div className="card" style={{ width: "100%", marginTop: 50, marginBottom: 0 }} >
                <h2>Consumazioni</h2>
                <ResponsiveContainer width="100%" height={400}>
                <BarChart
                    width={800}
                    height={400}
                    animationDuration={500}
                    data={consumptionsData}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" opacity={0.3} vertical={false} />
                    <XAxis dataKey="name" fontSize={12} textAnchor="end" />
                    <YAxis />
                    <Tooltip contentStyle={{ backgroundColor: "#202020", borderRadius: 10, borderWidth: 0 }} cursor={{fill: "grey", radius: [5, 5, 0, 0]}}/>
                    <Legend />
                    <Bar dataKey="consumazioni" fill="#8884d8" radius={[5, 5, 0, 0]}/>
                    <Bar dataKey="ingressi" fill="#82ca9d" radius={[5, 5, 0, 0]} />
                </BarChart>
                </ResponsiveContainer>
            </div>
            <div className="card" style={{ width: "100%", marginTop: 50, marginBottom: 0 }} >
                <h2>Affluenza</h2>
                <ResponsiveContainer width="100%" height={window.innerHeight /3}>
                <AreaChart
                    width={800}
                    height={400}
                    animationDuration={500}
                    data={entrancesData}
                    syncId={"anyId"}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" opacity={0.3} vertical={false} />
                    <XAxis dataKey="name" fontSize={12} textAnchor="end" />
                    <YAxis />
                    <Tooltip contentStyle={{ backgroundColor: "#202020", borderRadius: 10, borderWidth: 0 }} cursor={{fill: "grey", radius: [5, 5, 0, 0]}}/>
                    <Legend />
                    <Area dataKey="Persone" fill="#8884d8" stroke="#8884d8" />
                </AreaChart>
                </ResponsiveContainer>
                <h2>Vendite</h2>
                <ResponsiveContainer width="100%" height={window.innerHeight /3}>
                <AreaChart
                    width={800}
                    height={300}
                    animationDuration={500}
                    data={entrancesData}
                    syncId={"anyId"}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" opacity={0.3} vertical={false} />
                    <XAxis dataKey="name" fontSize={12} textAnchor="end" />
                    <YAxis />
                    <Tooltip contentStyle={{ backgroundColor: "#202020", borderRadius: 10, borderWidth: 0 }} cursor={{fill: "grey", radius: [5, 5, 0, 0]}}/>
                    <Legend />
                    <Area name="Consumazioni" dataKey="Vendite" fill="#82ca9d" stroke="#82ca9d" />
                </AreaChart>
                </ResponsiveContainer>
            </div>
            <div className="card" style={{ width: "100%", marginTop: 50, marginBottom: 0 }} >
                <h2>Ultimo evento</h2>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div>
                        <h3>{lastEvent.eventName}</h3>
                        <p>{new Date(lastEvent.eventStart).toLocaleDateString("it-IT", { day: "numeric", month: "short" })}</p>
                    </div>
                    <div>
                        <p>Ingressi: {lastEvent.entrances}</p>
                        <p>Consumazioni: {lastEvent.consumptions}</p>
                    </div>
                </div>
            </div>
        </div>
    )


}


export default EventData;