import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { useNavigate } from "react-router-dom";


const EventCalendar = (props) => {


    const navigate = useNavigate();

    // Show a dot under each day independently of the events
    const showDetails = (event) => {
        navigate("/event-details", { state: event });
    }

    const tileContent = ({ date, view }) => {
        // Add class to tiles in month view only
        if (view === 'month') {
            for (let i = 0; i < props.events.length; i++) {
                // Consider only the day of the event
                if (date.toLocaleDateString("it-IT") === props.events[i].shownStartDate) {
                    return (<div style={{ backgroundImage: "url(" + props.events[i].iconUrl	 + ")", backgroundSize: "cover", width: 80, height: 80, borderRadius: "10%", display: "flex"}}>
                        <p className="glass" style={{ fontSize: 12, textAlign: "center" }}>{props.events[i].name}</p>
                    </div>)
                }
            }
            return <div style={{ width: 40, height: 60 }}></div>
        }
    }
    return (
        <div>
            <Calendar calendarType="iso8601" tileContent={tileContent} navigationLabel={({ date, view }) => {
                return <h2 style={window.innerWidth < 600 ? {} : { width: 200 }}>{date.toLocaleString('default', { month: 'long', year: 'numeric' })}</h2>
            }}
                nextLabel={<h2 style={{ marginInline: 10 }}>{'>'}</h2>}
                prevLabel={<h2 style={{ marginInline: 10 }}>{'<'}</h2>}
                next2Label={<h2 style={{ marginInline: 10 }}>{'>>'}</h2>}
                prev2Label={<h2 style={{ marginInline: 10 }}>{'<<'}</h2>}
                showNeighboringMonth={false}
                minDetail="year"
                hover={true}
                onClickDay={(value) => {
                    let date = value.toLocaleDateString("it-IT");
                    let filteredEvents = props.events.filter(event => event.shownStartDate === date);
                    if (filteredEvents.length === 0) {
                        return;
                    }
                    showDetails(filteredEvents[0]);
                }}
            />
        </div>
    );
}
export default EventCalendar;

