import React from "react";
import { IoLogoGoogle, IoLogoApple } from "react-icons/io5"
import { useRef, useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import Clubpass from "../images/clubpass.png";
import PartyImage from "../images/party_login.jpg";
export const Login = () => {
    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = "/";

    const userRef = useRef();
    const errRef = useRef();

    const [email, setEmail] = useState("");
    const [pwd, setPwd] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [loginErr, setLoginErr] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, []);

    useEffect(() => {
        setErrMsg("");
    }, [email, pwd]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        fetch("https://backend.clubpass.it/login", {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ username: email, password: pwd }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("Success:", data);

                if (data.auth) {
                    setLoginErr(false);
                    setAuth({
                        username: email,
                        name: data.name,
                        surname: data.surname,
                        role: data.role,
                    });
                    if (data.role.organizer === 1) {
                    navigate("/dashboard", { replace: true });
                    }
                    else {
                        navigate(from, { replace: true });
                    }
                } else {
                    setLoginErr(true);
                }
            })
            .catch((err) => {
                console.error("Error", err);
            });
    };

    const googleLoginHandler = () => {
        window.open("https://backend.clubpass.it/login/federated/google", "_self");
    };
    const appleLoginHandler = () => {
        window.open("https://backend.clubpass.it/login/federated/apple", "_self");
    };

    return (
        <div className="container" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            {
                window.innerWidth > 600 &&
                <div style={{
                    width: "80%", height: "100%", backgroundImage: "url(" + PartyImage + ")", backgroundSize: "cover", backgroundPosition: "center", overflow: "hidden",
                    marginRight: "-10%",
                    zIndex: -1
                }}>

                    {/* ClubPass Logo at top left */}
                    <img src={Clubpass} alt="Clubpass" style={{ position: "absolute", top: 0, left: 40, width: "20%", minWidth: "300px", maxWidth: "400px", borderRadius: "10px", margin: "auto", marginBottom: 0 }} />

                    {/* Div that create linear opacity */}
                    <div style={{ width: "100%", height: "100%", background: "linear-gradient( to right, rgba(0, 0, 0, 0) 0%, #121212 90% )" }}>
                </div>
            </div>
            }
            <div className="card" style={{
                width: "600px",
                backgroundColor: "transparent",
                boxShadow: "none",
                gap: "20px",
            }}>
                {
                    window.innerWidth <= 600 &&
                    <img src={Clubpass} alt="Clubpass" style={{ width: "100%", marginTop: "50px", marginBottom: 0 }} />
                }
                {
                    window.innerWidth > 600 &&
                    <h1 style={{ fontSize: 30 }}>Bentornato!</h1>
                }
                <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <div class="form__group field">
                        <input
                            type="text"
                            id="email"
                            className="form__field"
                            placeholder="Email"
                            ref={userRef}
                            autoComplete="off"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            required
                        />
                        <label for="email" class="form__label">Email</label>
                    </div>

                    <div class="form__group field">
                        <input
                            type="password"
                            id="password"
                            className="form__field"
                            placeholder="Password"
                            onChange={(e) => setPwd(e.target.value)}
                            value={pwd}
                            required
                        />
                        <label for="password" class="form__label">Password</label>
                    </div>
                    <p id="errnote" className={loginErr ? "istructions" : "offscreen"}>
                        Username o password errati
                    </p>
                    <input type="submit" className="button" value="Accedi" />
                </form>
                <button className="button" onClick={googleLoginHandler} style={{ borderStyle: "solid", backgroundImage: "none", borderColor: "#d5d5d5", borderWidth: "2px", backgroundColor: "transparent", color: "#d5d5d5", width: "240px" }}>
                    <IoLogoGoogle size={20} color="#d5d5d5" style={{ marginRight: "10px" }} />
                    Accedi con Google
                </button>
                <button className="button" onClick={appleLoginHandler} style={{ borderStyle: "solid", backgroundImage: "none", borderColor: "#d5d5d5", borderWidth: "2px", backgroundColor: "transparent", color: "#d5d5d5", width: "240px" }}>
                    <IoLogoApple size={20} color="#d5d5d5" style={{ marginRight: "10px" }} />
                    Accedi con Apple
                </button>

                <p className="text-small">
                    Non ancora registrato? &nbsp;
                    <span>
                        <Link to="/register">Registrati</Link>
                    </span>
                </p>
                <p className="text-small">
                    <Link to="/reset">Password dimenticata</Link>
                </p>
            </div>
        </div>
    );
};
