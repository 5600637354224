import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import Loader from "../components/Loader";

export const LoginAuth = () => {
    const { setAuth } = useAuth();

    const navigate = useNavigate();

    const onlineCheck = async () =>
    {
        fetch('https://backend.clubpass.it/whoami',
            {
                method: 'GET',
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            .then((response) => response.json())
            .then((data) => {

                if (data.auth) {
                    setAuth({username: data.email, name: data.name, surname: data.surname, role: data.role});
                    if (data.role.organizer === 1) {
                        navigate("/dashboard", {replace: true});
                    }
                    else {
                        navigate("/", {replace: true});
                    }
                }
                else {

                    navigate("/login");
                }
            })
            .catch((err) => {
                console.error('Error', err);
                navigate("/login");

            })
    }

    useEffect(() => {
        console.log("Iniziato");
        onlineCheck();
        
    }, [])

    return (
        <div>
            <h1>Redirect</h1>
            <Loader />
        </div>
    );

};

export default LoginAuth;